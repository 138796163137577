<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: "App",
  data() {
    return {}
  },
  computed: {},
}
</script>
<style lang="less">
</style>
