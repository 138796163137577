
// sys
export const FileApi = "/api/file/FileApi2"
export const SysEnum_create = "/api/SysEnumController/create"
export const SysEnum_updateById = "/api/SysEnumController/updateById"
export const SysEnum_getById = "/api/SysEnumController/getById"
export const SysEnum_getPage = "/api/SysEnumController/getPage"
export const SysEnumImgType_Delete = "/api/SysEnumController/deleteById"
export const PermissionApi = "/api/user_api/PermissionApi"
export const systemInfo = "/api/system/info"
// log
export const logPage = "/api/LogController/getPage"
export const logCreate = "/api/LogController/create"

// user
export const User_getPage = "/api/UserController/getPage"
export const User_login = "/api/UserController/login"
export const User_logout = "/api/UserController/logout"
export const User_getCurrentUserInfo = "/api/UserController/getCurrentUserInfo"

// img
export const Img_getInfo = "/api/img/getInfo"
export const Img_getPage = "/api/img/getPage"
export const Img_create = "/api/img/create"
export const Img_delete = "/api/img/deleteByIds"
export const Img_update = "/api/img/update"

// Customer
export const Customer_Page = "/api/CustomerController/getPage"
export const Customer_update = "/api/CustomerController/updateById"
export const Customer_create = "/api/CustomerController/create"
